import Vue from 'vue'
import {
    Button,Upload,Form,FormItem,TimePicker,Image,Dialog,Select,Col,Card,Row,
    DatePicker,Table,TableColumn,Pagination,Dropdown,DropdownItem,DropdownMenu
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Button);Vue.use(Upload);Vue.use(Form);Vue.use(DatePicker);Vue.use(Dialog);Vue.use(Col);Vue.use(Card);
Vue.use(Image);Vue.use(TimePicker);Vue.use(FormItem);Vue.use(DropdownMenu);;Vue.use(Select);Vue.use(Row);
Vue.use(Table);Vue.use(TableColumn);Vue.use(Pagination);Vue.use(Dropdown);Vue.use(DropdownItem);


