import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element'
import './plugins/ant'
import 'ant-design-vue/dist/antd.css';

import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

//常量
import const_ from './const';
Vue.prototype.$const = const_

//ajax请求
import axios from './plugins/axios';
Vue.prototype.$ajax = axios

//上传图片或文件
import upload from './plugins/upload';
Vue.prototype.$upload = upload

//MD5
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;


//提示信息
import { Message } from 'element-ui'
Vue.prototype.$message = Message
import { MessageBox } from 'element-ui'
Vue.prototype.$messageBox = MessageBox

//echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts

//粒子效果
import VueParticles from 'vue-particles'
Vue.use(VueParticles)


import '../public/tinymce/skins/ui/oxide/content.min.css'
import '../public/tinymce/skins/ui/oxide/content.css'
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
